
import { withRouter, Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Bundle from './Bundle.jsx'

let PsyTestResultPage = function () {
  return <Bundle load={() => import('./pages/PsyTest/PsyTestResultPage')}>
    {(Com) => <Com />}
  </Bundle>;
};
let PsyTestLastPage = function () {
  return <Bundle load={() => import('./pages/PsyTest/PsyTestLastPage')}>
    {(Com) => <Com />}
  </Bundle>;
};
let MindTestShare = function () {
  return <Bundle load={() => import('./pages/MindTest/MindTest')}>
    {(Com) => <Com />}
  </Bundle>;
};
let MindTestContent = function () {
  return <Bundle load={() => import('./pages/MindTest/MindTestContent')}>
    {(Com) => <Com />}
  </Bundle>;
};
let individuationTest = function () {
  return <Bundle load={() => import('./pages/MindTest/individuation/individuationTest')}>
    {(Com) => <Com />}
  </Bundle>;
};
let individuateTestKnowH5 = function () {
  return <Bundle load={() => import('./pages/MindTest/individuation/individuateTestKnow')}>
    {(Com) => <Com />}
  </Bundle>;
};
let individuateTestConH5 = function () {
  return <Bundle load={() => import('./pages/MindTest/individuation/individuateTestCon')}>
    {(Com) => <Com />}
  </Bundle>;
};
function App(props) {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/PsyTestResult" component={PsyTestResultPage} />
          <Route path="/PsyTestLast" component={PsyTestLastPage} />
          <Route path="/MindTestShare" component={MindTestShare} />
          <Route path="/MindTestContent" component={MindTestContent} />
          <Route path="/individuationTest" component={individuationTest} />
          <Route path="/individuateTestKnow" component={individuateTestKnowH5} />
          <Route path="/individuateTestCon" component={individuateTestConH5} />
        </Switch>
      </Router>
    </>
  );
}

export default App;